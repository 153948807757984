
import { Options, Vue } from "vue-class-component";
import { Swiper, SwiperSlide } from "swiper/vue";

import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

@Options({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    items: Array,
  },
  data() {
    return {};
  },
})
export default class NewsPage extends Vue {}
